:root {
	--fc-today-bg-color: rgba(196, 281, 253, 0.15);
	--fc-now-indicator-color: red;
	--fc-button-text-color: #fff;
	--fc-button-bg-color: #2C3E50;
	--fc-button-border-color: #2C3E50;
	--fc-button-hover-bg-color: #1e2b37;
	--fc-button-hover-border-color: #1a252f;
	--fc-button-active-bg-color: #1a252f;
	--fc-button-active-border-color: #151e27;
}

.footer {
  bottom: 0;
  height: 30px; /* Set the fixed height of the footer here */
  line-height: 30px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.pswp__custom-caption a {
  color: #fff; 
  text-decoration: underline;
}


.turbo-progress-bar {
  height: 3px;
  background-color: violet;
}

/*
#pswp-container {
  position: fixed;
  left: 100px;
  right: 100px;
  top: 50px;
  bottom: 50px;    
}*/
/*.pswp {
  position: relative !important;
}
*/

/* Quantity Input 
-------------------------------------------------- */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

input[type=number] {
  -moz-appearance: textfield !important;
}

/* Other imports for CSS or whole directories can go here if needed */

@import "scaffolds";
@import "fullcalendar-vars";
@import "actiontext";
@import 'tippy.js/dist/tippy.css';
@import 'tom-select/dist/css/tom-select.default.css';
@import "dropzone/dist/dropzone.css";
@import "dropzone/dist/basic.css";
@import "photoswipe/dist/photoswipe.css";

/*@import "../fontawesome/css/all";
@import "font-awesome";
*/
/*@import '@recogito/annotorious/dist/annotorious.min.css';*/

@tailwind base;

@layer base {
  h1 {
    @apply text-xl font-bold
  }
  h2 {
    @apply text-lg font-semibold text-gray-700
  }
  h3 {
    @apply font-semibold
  }
  a {
    @apply text-violet-500 hover:underline hover:text-violet-900
  }
  p {
    @apply text-sm
  }
  label {
  	@apply block text-gray-600 font-bold 
  }
  input[type=text], input[type=email], input[type=password], input[type=number], input[type=date], input[type=time], input[type=string], input[type=datetime], input[type=search] {
    @apply w-full my-2 px-4 py-2 block rounded-lg text-gray-800 bg-gray-100 appearance-none focus:outline-none focus:ring-2 focus:ring-violet-200
  }
  input[type=number] {
    @apply rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-200
  }
  input[type=radio] {
    @apply w-5 h-5 my-2 mx-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-violet-200
  }
  input:disabled, select:disabled {
    @apply bg-gray-300 cursor-not-allowed !important
  }
  input:disabled+label {
    @apply text-gray-500
  }
  select {
    @apply w-full my-2 px-4 py-2 rounded-lg text-gray-800 bg-gray-100 appearance-none bg-no-repeat bg-right bg-contain cursor-pointer focus:outline-none focus:ring-2 focus:ring-violet-200;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 36 36' fill='var(--color-primary)'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  }
  textarea {
    @apply w-full my-2 px-4 py-2 rounded-lg text-gray-800 bg-gray-100 leading-tight focus:outline-none focus:ring-2 focus:ring-violet-200
  }
}

@tailwind components;

@layer components {
  abbr[title] {
    @apply no-underline;
  }
  .btn-form {
    @apply w-auto px-2 py-2 mb-2 rounded-lg font-semibold text-center text-gray-200 bg-gray-700 hover:bg-gray-500 hover:text-white hover:no-underline focus:outline-none focus:bg-gray-500 cursor-pointer
  }
  .selected-tab {
    @apply bg-gray-300
  }
  .collection_radio_buttons {
    @apply self-center font-normal text-gray-800
  }
  .error {
    @apply bg-red-200 text-red-600 px-3
  }
  .avatar-circle {
    @apply w-12 h-12 rounded-full m-1 no-underline flex items-center justify-center
  }
  .initials {
    @apply text-lg leading-6 flex flex-row items-center
  } 
  .form-card {
    @apply m-1 p-2 sm:p-3 bg-white rounded-lg shadow-md mx-auto md:max-w-4xl
  }
  .required {
    @apply no-underline;
  }
  .quantity-input {
    @apply rounded-none text-center !important
  }
  .quantity-button {
    @apply my-2 pt-1 w-10 text-center text-gray-800 bg-gray-200 block hover:bg-gray-500 hover:text-white cursor-pointer appearance-none
  }
  .quantity-down {
    @apply text-center rounded-l-lg
  }
  .quantity-up {
    @apply text-center rounded-r-lg
  }
  .toggle-wrapper {
    @apply relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in
  }
  .toggle-checkbox {
    @apply m-0 p-0 absolute block w-6 h-6 rounded-full bg-white border-4 border-red-500 appearance-none cursor-pointer focus:border-red-500;
  }
  .calendar-email {
    @apply border-2 border-dashed border-white
  }
  .link-box-bottom {
    @apply text-xl cursor-pointer border-t-2 border-gray-300 bg-white w-full h-full p-1 text-center md:py-2 lg:py-3
  }
  .link-nav {
    @apply no-underline text-gray-500 hover:bg-gray-600 hover:text-gray-100
  }
}

@tailwind utilities;

/* Moved Select and Others outside @layer so they are not purged in production (as they do not appear in flat HTML */

.ts-wrapper .ts-control { 
  @apply text-base mb-0 items-center bg-none shadow-none h-10 w-full my-2 px-4 py-2 rounded-lg text-gray-800 bg-gray-100 border-none appearance-none bg-no-repeat bg-right bg-contain cursor-pointer focus:outline-none focus:ring-2 focus:ring-violet-200 !important;
}
.ts-wrapper ::placeholder { 
  @apply text-sm font-semibold !important;
}
.ts-dropdown {
  @apply bg-gray-100 rounded-lg border-none focus:outline-none focus:ring-2 focus:ring-violet-200 !important;
}
.ts-dropdown-content {
  @apply mt-0 text-sm !important;  
}
.ts-dropdown .option:hover {
  @apply rounded-lg text-gray-800 bg-violet-100 border-none m-0 outline-none !important;
}
.ts-dropdown-content > .option.selected {
  @apply bg-violet-200 !important;
}
.ts-dropdown-content > .option[aria-disabled="true"] {
  @apply font-bold text-gray-700 px-2 !important;
}
.multi .ts-control .item {
  @apply bg-gray-300 text-gray-800 font-light text-xs border-gray-400 bg-none shadow-none !important;
}
.flatpickr-time {
  @apply h-full w-full my-2 rounded-lg text-gray-800 bg-gray-100 !important;
}
.fc-toolbar-title {
  @apply text-base font-semibold text-center my-2 !important
}
.fc-button {
  @apply text-sm font-medium !important
}
.fc {
  @apply text-sm !important
}
.fc-day {
  @apply font-normal 
} 
.fc-timegrid-slot-minor {
  @apply border-t-0 !important
}
.fc-event-main {
  @apply cursor-default font-normal text-xs !important  
} 
.fc-event-time {
  @apply order-last cursor-default
}
.fc-event-time::after {
  content: "" !important;
}
.fc-timegrid-slot-label-cushion {
  @apply text-xs text-gray-500 !important
}
.fc-daygrid-dot-event .fc-event-time {
  @apply order-first  
}
.fc-header-toolbar {
  @apply grid grid-cols-1 lg:grid-cols-3 !important
}
.fc-button-group {
  @apply w-full h-8 text-xs !important
}
.fc-col-header {
  @apply h-16 !important
}
.fc-col-header-cell-cushion {
  @apply w-10 h-10 lg:w-12 lg:h-12 text-xs lg:text-sm rounded-full m-1 align-middle no-underline
}
.fc-day-today:not(.fc-daygrid-day) a {
  @apply font-semibold bg-violet-500 text-gray-100 hover:text-gray-300
}
.fc-day-today .fc-daygrid-day-number {
  @apply rounded-full w-7 h-7 text-center font-semibold bg-violet-500 text-gray-100 hover:text-gray-300
}
.fc-day-today .fc-day-grid-day-number {
  @apply rounded-full
}
.fc-list-day-text {
  @apply px-2
} 
.fc-list-day-side-text {
  @apply px-2
}
.fc-list-event-time {
  @apply cursor-default text-gray-600
}
.fc-list-event-dot {
  @apply invisible
}
.fc-list-event-title {
  @apply cursor-default no-underline hover:no-underline !important
}
.fc-list-event-title a {
  @apply cursor-default no-underline hover:no-underline !important
}
.tippy-box {
  @apply bg-white text-gray-800 rounded-lg shadow-md border-2 border-gray-100
}
.tippy-box a {
  @apply text-violet-500 hover:underline hover:text-violet-900
}
.tippy-box > .tippy-svg-arrow {
  @apply text-gray-400 fill-current 
}
button.pswp__button--download-button, button.pswp__button--info-button>div {
  @apply text-white
}
.pswp__custom-caption {
  @apply absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-violet-200/75 text-violet-700 p-2 text-xs rounded-t-md pointer-events-none !important
}
.alert-success {
  @apply my-4 bg-violet-200 relative text-violet-600 py-3 px-3 rounded-lg
}
.alert-notice {
  @apply my-4 bg-green-200 relative text-green-600 py-3 px-3 rounded-lg
}  
.alert-error {
  @apply my-4 bg-red-200 relative text-red-600 py-3 px-3 rounded-lg
}
.alert-alert {
  @apply my-4 bg-yellow-200 relative text-yellow-600 py-3 px-3 rounded-lg
}
.close {
    @apply text-xl py-2 absolute bg-transparent font-semibold leading-none  right-0 top-0 my-2 mr-6 outline-none focus:outline-none
}
.dropzone {
  @apply my-2 border-2 rounded-lg border-gray-400 border-dashed;

  &.dz-drag-hover {
    @apply border-2 rounded-lg border-gray-200 border-dashed;

    .dz-message { 
      opacity: 0.9;
    }
  }
}
/* Autocomeplte */
.autocomplete-results {
  @apply w-full leading-normal rounded-lg text-sm bg-gray-300 text-gray-500 relative -mt-2 z-10
}
.autocomplete-result {
  @apply block px-4 py-1 cursor-pointer hover:bg-gray-400 hover:text-gray-100 hover:no-underline hover:rounded-lg
}
/* Annotorious */
.r6o-delete {
  @apply block !important /* Removed extra emtpy space */ 
}
.r6o-delete-wrapper {
  @apply text-base bg-red-500 !important
}
.r6o-btn {
  @apply w-auto px-2 py-1 rounded-lg border-none  font-semibold text-gray-200 bg-gray-700 hover:bg-gray-500 hover:text-white focus:outline-none focus:bg-gray-500 cursor-pointer !important
}
.r6o-btn.outline {
  @apply bg-gray-500 hover:bg-gray-300 !important
}
/* Action Text */
.rich_text_area[role="textbox"] {
  @apply w-full my-2 px-4 py-2 block rounded-lg text-gray-800 bg-gray-100 border-none leading-tight focus:outline-none focus:ring-2 focus:ring-violet-200
}
.rich_text_area ul {
  @apply list-disc list-inside pl-2
}
.rich_text_area ol {
  @apply list-decimal list-inside pl-2
}
.trix-content ul {
  @apply list-disc list-inside
}
.trix-content ol {
  @apply list-decimal list-inside
}
.trix-button-group--file-tools {
  @apply invisible
}
.trix-button--icon-attach {
  @apply hidden
}
.trix-button--icon-code {
  @apply hidden
}
.trix-button--icon-quote {
  @apply hidden
}
.trix-button--icon-decrease-nesting-level {
  @apply hidden
}
.trix-button--icon-increase-nesting-level {
  @apply hidden
}
.do-not-purge-list {
  @apply bg-violet-800 bg-violet-500 bg-white border-2 border-gray-200
}
.pagy-info {
  @apply ml-2 mb-2 text-gray-600 text-sm  
}
.pagination {
  @apply flex justify-center my-2 py-2 !important
}
.pagination span {
  @apply bg-white rounded-lg py-1 px-2 mx-1 !important
}
.pagination a {
  @apply text-violet-500 no-underline !important
}
.pagination a:hover {
  @apply text-violet-900 no-underline font-semibold bg-gray-200 !important
}
.editable--hidden {
  @apply hidden
}
.quick-edit-input {
  @apply w-full flex-shrink-0 mx-1 px-1 rounded-lg text-gray-600 bg-gray-100 appearance-none focus:outline-none focus:ring-2 focus:ring-violet-200
}
input[type=checkbox] {
  @apply w-5 h-5 my-2 mx-1 text-gray-800 bg-gray-100 cursor-pointer border-2 appearance-none hover:bg-gray-300
}
input[type=checkbox]:checked {
  @apply bg-violet-300;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
}
input[type=checkbox]:disabled {
  @apply border-gray-100
}
